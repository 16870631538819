@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@layer base {
    body {
        @apply font-gilroy;
        background-color: #E5E5E5;
    }

}

.list-item::before {
    content: "";
    width: 5px;
    height: 5px;
    background: black;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
}